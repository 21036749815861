import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useSprings, animated, interpolate } from "react-spring"

import { Text, theme, A } from "src/style"

const useMobileDetect =
  typeof window !== "undefined"
    ? require("use-mobile-detect-hook")
    : () => {
        return { isMobile: () => false }
      }

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => (props.clickable ? "all" : "none")};
`

const Slide = styled(Container)`
  opacity: ${props => props.opacity};
  transform: ${props => props.transform};
  pointer-events: ${props => (props.clickable ? "all" : "none")};
  cursor: ${props => (props.clickable ? "pointer" : "text")};
  transform: translateZ(0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
`

const hoverMixin = css`
  transform: scale(1.017);
  transition: transform 0.3s ease-out;
  backface-visibility: hidden;
  transform-origin: center;
  perspective: 1000;
`

const Headline = styled(Text).attrs({
  fontFamily: "serif",
  fontWeight: 500,
  fontSize: ["2.3rem", "xl", "xxl"],
})`
  width: 75%;
  text-align: center;
  line-height: 0.95;
  cursor: pointer;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.3s ease-out;
  pointer-events: ${props => (props.clickable ? "all" : "none")};
  -webkit-font-smoothing: subpixel-antialiased;

  ${theme.mediaQueries.small} {
    width: 82%;
  }

  ${props => props.hovered && hoverMixin}

  &:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease-out;
    transform-origin: center;
    transform-style: preserve-3d;
    perspective: 1000;
  }
`
const Logo = styled(animated.img)`
  cursor: pointer;
  backface-visibility: hidden;
  transform: translateZ(0);
  transform-style: preserve-3d;
  perspective: 1000;
  user-select: none;
  height: 2.4rem;
  max-height: 40px;
  margin-top: 1.2em;
  display: none;

  ${theme.mediaQueries.medium} {
    display: block;
  }
`

const Arrow = styled(Text)`
  font-family: "serif";
  font-weight: 500;
  backface-visibility: hidden;
  transform: translateZ(0);
  transform-style: preserve-3d;
  display: none;
  font-size: calc(1.25rem * 2);
  text-align: center;
  transform: translate(-0.7em);
  width: 100%;
  height: 0px;
  margin-top: 0.1em;

  ${theme.mediaQueries.small} {
    font-size: calc(1.25rem * 4.5);
    transform: translate(-0.9em);
  }
`

const LinkOverlay = styled(Headline)`
  opacity: 0;
  z-index: 999;
`

const SlideIndex = styled(Text).attrs({ fontSize: "sm" })`
  position: fixed;
  left: 0;
  bottom: ${theme.space[5]};
  right: 0;
  text-align: center;
  display: none;
  letter-spacing: 0.015em;

  ${theme.mediaQueries.small} {
    display: block;
  }
`

const SPRING_CONFIG = { duration: 310 }

const getSlideProps = (
  project,
  index,
  currentIndex,
  navColor,
  length,
  smallScale,
  width
) => {
  const color = navColor === "BLACK" ? "#000" : "#fff"

  if (typeof window === `undefined`) {
    return {
      scale: 1,
      x: 0,
      color,
      opacity: index === currentIndex ? 1 : 0,
      logoOpacity: 0,
      arrowDisplay: "none",
      config: SPRING_CONFIG,
    }
  }

  // If we are on the last slide
  // show the first one
  if (currentIndex + 1 === length && index === 0) {
    return {
      scale: smallScale,
      opacity: 1,
      logoOpacity: 0,
      arrowDisplay: "inline-block",
      x: width / 2,
      color,
    }
  }

  // if slide is coming up on the left of the FIRST slide....
  if (currentIndex === 0 && index + 1 === length) {
    return {
      scale: smallScale,
      opacity: 1,
      logoOpacity: 0,
      arrowDisplay: "none",
      x: -(width / 2),
      color,
    }
  }

  // if slide is not directly coming up on either side
  // it's display: none and we don't need to worry about it
  if (index < currentIndex - 2 || index > currentIndex + 2)
    return {
      opacity: 0,
      x: 0,
      color,
      scale: 0,
      logoOpacity: 0,
      arrowDisplay: "none",
    }

  // if slide is coming up on the left side....
  if (index === currentIndex - 2) {
    return {
      scale: smallScale,
      opacity: 1,
      logoOpacity: 0,
      arrowDisplay: "none",
      x: -(width / 2) - 1000,
      color,
    }
  }

  // if slide is coming up on the right side....
  if (index === currentIndex + 2) {
    return {
      scale: smallScale,
      opacity: 1,
      logoOpacity: 0,
      arrowDisplay: "inline-block",
      x: width + 1000,
      color,
    }
  }

  // if slide is present on the left side....
  if (index === currentIndex - 1) {
    return {
      scale: smallScale,
      opacity: 1,
      logoOpacity: 0,
      arrowDisplay: "none",
      x: -(width / 2),
      color,
    }
  }

  // if slide is present on the right side....
  if (index === currentIndex + 1) {
    return {
      scale: smallScale,
      opacity: 1,
      logoOpacity: 0,
      arrowDisplay: "inline-block",
      x: width / 2,
      color,
    }
  }

  // if slide is current but it has long text...
  if (project.long_text && !!project.long_text.text) {
    return {
      scale: 1,
      x: 0,
      opacity: 0,
      color,
      immediate: true,
      logoOpacity: 0,
      arrowDisplay: "none",
    }
  }

  // Otherwise, slide is current and normal
  return {
    scale: 1,
    x: 0,
    opacity: 1,
    logoOpacity: 1,
    arrowDisplay: "none",
    config: SPRING_CONFIG,
    color,
  }
}

const HomepageSlides = ({
  projects,
  currentIndex,
  navColor,
  goNext,
  goBack,
  width,
}) => {
  const device = useMobileDetect()
  const smallScale = device.isMobile() ? 0.50 : 0.22

  const slideProps = useSprings(
    projects.length,
    projects.map((project, index) =>
      getSlideProps(
        project,
        index,
        currentIndex,
        navColor,
        projects.length,
        smallScale,
        width
      )
    )
  )

  const currentProject = projects[currentIndex]
  const hasLongText =
    !!currentProject.long_text && !!currentProject.long_text.text

  const [isHoveredIndex, setHoveredIndex] = useState(null)

  return (
    <Container clickable={hasLongText ? 0 : 1}>
      <>
        {slideProps.map(
          ({ scale, x, opacity, color, logoOpacity, arrowDisplay }, i) => {
            const project = projects[i]
            const onClick = () => {
              if (
                (currentIndex + 1 === projects.length && i === 0) ||
                currentIndex < 0
              ) {
                return goNext()
              }

              if (currentIndex === 0 && i === projects.length - 1) {
                return goBack()
              }

              switch (currentIndex - i) {
                case -1:
                  return goNext()
                case 1:
                  return goBack()
                default:
                  // do nothing
                  return null
              }
            }
            const pointerEvents = i !== currentIndex

            return (
              <Slide
                key={i}
                onClick={onClick}
                clickable={pointerEvents ? 1 : 0}
                style={{
                  opacity,
                  transform: interpolate(
                    [x, scale],
                    (x, scale) => `translate3d(${x}px, 0px, 0) scale(${scale})`
                  ),
                }}
              >
                {project.title && (
                  <Headline
                    style={{ color }}
                    hovered={isHoveredIndex === i ? 1 : 0}
                    clickable={hasLongText ? 0 : 1}
                  >
                    {project.title.text}
                  </Headline>
                )}

                <Arrow style={{ display: arrowDisplay }}>→</Arrow>

                {project.logo && (
                  <Logo
                    src={project.logo.url}
                    style={{ opacity: logoOpacity }}
                  />
                )}
              </Slide>
            )
          }
        )}
      </>
      {projects[currentIndex].linked_project &&
        projects[currentIndex].linked_project.uid && (
          <LinkOverlay
            onMouseOver={() => setHoveredIndex(currentIndex)}
            onMouseOut={() => setHoveredIndex(null)}
            clickable={hasLongText ? 0 : 1}
          >
            <A to={`/work/${projects[currentIndex].linked_project.uid}`}>
              {projects[currentIndex].title.text}
            </A>
          </LinkOverlay>
        )}
      <SlideIndex>
        {currentIndex + 1}/{projects.length}
      </SlideIndex>
    </Container>
  )
}

export default HomepageSlides
