import React from "react"
import { graphql } from "gatsby"

import MetaTitle from "src/components/MetaTitle"
import MetaTags from "src/components/MetaTags"
import Homepage from "src/components/Homepage"

export default ({
  data: {
    homepage: {
      data: { projects },
    },
  },
}) => {
  return (
    <>
      <MetaTitle />
      <MetaTags />
      <Homepage projects={projects} />
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    homepage: prismicHomepage(slugs: { eq: "homepage" }) {
      data {
        projects {
          title {
            html
            text
          }
          image {
            dimensions {
              width
              height
            }
            responsive_medium {
              url
            }
            responsive_large {
              url
            }
          }
          linked_project {
            id
            uid
          }
          background_color
          navigation_color
          media {
            url
          }
          vimeo {
            video_id
            uri
            embed_url
            thumbnail_url
          }
          long_text {
            text
          }
          logo {
            url
          }
          slideshow {
            document {
              data {
                images {
                  image {
                    dimensions {
                      width
                      height
                    }
                    responsive_medium {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
