import React from "react"
import styled from "styled-components"
import { useSprings, animated } from "react-spring"
import FilePlayer from "react-player/lib/players/FilePlayer"

import HomepageSubslides from "src/components/HomepageSubslides"
import HomepageAnimatedImage from "src/components/HomepageAnimatedImage"
import { Text, Box, A, theme } from "src/style"

const useMobileDetect =
  typeof window !== "undefined"
    ? require("use-mobile-detect-hook")
    : () => {
        return { isMobile: () => false }
      }

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  ${props =>
    props.canClick &&
    `
    pointer-events:  none;
  `}
`

const Backdrop = styled(animated.div)`
  width: 100vw;
  height: 56.25vw;
  min-height: 101vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.mediaQueries.portrait} {
    display: flex;
    justify-content: center;
  }
`

const Player = styled(FilePlayer)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  opacity: ${props => props.opacity};
  pointer-events: none;

  video {
    object-fit: cover;
  }
`

const BioText = styled(Text).attrs({
  fontFamily: "serif",
})`
  font-size: 2.3rem;
  line-height: 1.1em;
  text-align: center;
  z-index: 0;
  cursor: default;

  ${theme.mediaQueries.small} {
    text-align: left;
    font-size: 2.5rem;
  }

  ${theme.mediaQueries.large} {
    font-size: 4rem;
  }
`

const ReadMore = styled(Text).attrs({
  fontSize: "md",
  fontFamily: "sans",
})`
  display: inline;
  margin-left: 1em;
  pointer-events: all;

  ${theme.mediaQueries.medium} {
    fontsize: "lg";
  }
`

const SPRING_CONFIG = { duration: 650 }

const getSlideProps = (project, index, currentIndex) => {
  const defaultAttrs = {
    backgroundColor: project.background_color || "#fff",
    imageScale: 1,
    backgroundOpacity: 0,
    opacity: 0,
  }
  // if slide is not current we don't need to worry about it, just hide it
  if (index !== currentIndex) return defaultAttrs

  if (project.long_text && project.long_text.text)
    return { ...defaultAttrs, opacity: 1 }

  // Otherwise, slide is current
  return {
    ...defaultAttrs,
    opacity: 1,
    backgroundOpacity: 1,
    imageScale: 1.02,
    config: SPRING_CONFIG,
  }
}

const HomepageBackdrop = ({ projects, currentIndex, loaded }) => {
  const slideProps = useSprings(
    projects.length,
    projects.map((backdrop, index) =>
      getSlideProps(backdrop, index, currentIndex)
    )
  )

  const currentProject = projects[currentIndex]

  const device = useMobileDetect()

  return (
    <Container canClick={!currentProject.title}>
      {slideProps.map(({ opacity, backgroundOpacity, backgroundColor }, i) => {
        const project = projects[i]
        const video_url = project.media && project.media.url
        const img =
          project.image &&
          project.image.responsive_large &&
          project.image.responsive_large.url

        if (video_url && device.isMobile() && img) {
          return (
            <Backdrop
              style={{ backgroundColor, opacity: backgroundOpacity }}
              key={i}
            >
              <HomepageAnimatedImage src={img} current={i === currentIndex} />
            </Backdrop>
          )
        }

        if (video_url) {
          return (
            <Backdrop
              style={{ backgroundColor, opacity: backgroundOpacity }}
              key={i}
            >
              <Player
                url={video_url}
                controls={false}
                width="100%"
                height="100%"
                light
                muted
                loop
                playing={currentIndex === i && loaded}
                preload="auto"
                playsinline
                paused={loaded ? 1 : 0}
                style={{ background: "transparent" }}
              />
            </Backdrop>
          )
        }

        if (img) {
          return (
            <Backdrop
              style={{ backgroundColor, opacity: backgroundOpacity }}
              key={i}
            >
              <HomepageAnimatedImage src={img} current={i === currentIndex} />
            </Backdrop>
          )
        }

        const text = project.long_text && project.long_text.text

        if (text) {
          return (
            <Backdrop
              style={{
                backgroundColor,
                opacity,
                zIndex: 999,
                transform: "translateZ(100)",
              }}
              key={i}
            >
              <Box p={[7, 10]} maxWidth={["79vw", "97vw"]}>
                <BioText>
                  {text}
                  <A to="/agency">
                    <ReadMore>Read more</ReadMore>
                  </A>
                </BioText>
              </Box>
            </Backdrop>
          )
        }

        const slideshow = project.slideshow

        if (slideshow) {
          return (
            <Backdrop
              style={{
                backgroundColor,
                opacity,
                zIndex: 999,
                transform: "translateZ(100)",
                pointerEvents: "none",
              }}
              key={i}
            >
              <HomepageSubslides project={project} />
            </Backdrop>
          )
        }

        return <animated.div style={{ backgroundColor }} key={i} />
      })}
    </Container>
  )
}

export default HomepageBackdrop
