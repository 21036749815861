import React from "react"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"

import Icon from "src/components/Icon"
import { Box, darkTheme, theme, Text } from "src/style"

const Background = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 999;
  font-size: 0.95rem;
`

const LoadContainer = styled(Box)`
  position: absolute;
  bottom: 1.5em;
  width: 100%;

  ${theme.mediaQueries.small} {
    top: 55px;
    left: 5vw;
    width: auto;
  }

  ${theme.mediaQueries.medium} {
    left: 10vw;
  }

  ${theme.mediaQueries.large} {
    left: calc((100vw - 1300px) / 2);
  }

  ${theme.mediaQueries.xlarge} {
    left: calc((100vw - 1500px) / 2);
  }

  > div:first-child {
    position: relative;
    margin: auto;
    width: 7.2em;
    height: 3.2em;
    left: 50%;
    transform: translateX(-50%);

    ${theme.mediaQueries.small} {
      width: 8em;
      height: 4em;
      float: left;
      margin-left: 0;
      left: 0;
      transform: translateX(0);
    }

    > svg {
      height: auto;
    }
  }

  > div:nth-child(2) {
    position: fixed;
    top: 50%;
    margin-left: auto;
    width: 100%;
    text-align: center;
    margin-top: -2px;
    letter-spacing: 0.015em;

    ${theme.mediaQueries.small} {
      position: relative;
      top: 0;
      width: auto;
      float: left;
      margin-left: calc(8em - 5px);
      text-align: left;
    }
  }
`

const LoadingScreen = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Background>
        <LoadContainer>
          <Icon name="Logo" color="logo" />
          <Text fontFamily="sans">
            Loading...
          </Text>
        </LoadContainer>
      </Background>
    </ThemeProvider>
  )
}

export default LoadingScreen
