import React, { useState, useEffect, useCallback } from "react"
import { ThemeProvider } from "styled-components"

import { lightTheme, darkTheme, Style } from "src/style"
import Menu from "src/components/Menu"
import HomepageBackdrop from "src/components/HomepageBackdrop"
import HomepageSlides from "src/components/HomepageSlides"
import LoadingScreen from "src/components/LoadingScreen"
import useWindowSize from "src/utils/useWindowSize"

export default ({ projects }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true)
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  const [currentIndex, setIndex] = useState(0)

  //
  // Navigation functions
  //
  const navigate = newIndex => setIndex(newIndex)
  const goBack = () => {
    const newIndex =
      currentIndex - 1 < 0 ? projects.length - 1 : currentIndex - 1
    navigate(newIndex)
  }

  const goNext = () => {
    const newIndex = currentIndex + 1 >= projects.length ? 0 : currentIndex + 1
    navigate(newIndex)
  }

  //
  // Auto slideshow
  //
  const next = useCallback(goNext, [goNext])
  const back = useCallback(goBack, [goBack])

  useEffect(() => {
    if (loaded) {
      const timer = setTimeout(() => next(), 7000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [currentIndex, loaded, next])

  //
  // Styling functions
  //
  const currentProject = projects[currentIndex]
  const { navigation_color } = currentProject

  const themeName = navigation_color === "BLACK" ? "light" : "dark"
  const themeConfig = themeName === "light" ? lightTheme : darkTheme

  const theme = {
    ...themeConfig,
    colors: {
      ...themeConfig.colors,
    },
  }

  const dimensions = useWindowSize()

  return (
    <ThemeProvider theme={theme}>
      <>
        <HomepageBackdrop
          projects={projects}
          currentIndex={currentIndex}
          loaded={loaded}
        />

        <Menu />
        <Style />

        {loaded && (
          <HomepageSlides
            projects={projects}
            currentIndex={currentIndex}
            navColor={navigation_color}
            goNext={next}
            goBack={back}
            width={dimensions.windowWidth}
          />
        )}

        {!loaded && <LoadingScreen />}
      </>
    </ThemeProvider>
  )
}
