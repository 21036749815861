import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"
import { theme } from "src/style"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
`

const Image = styled.img`
  max-height: 65vh;
  max-width: 84vw;
  display: none;

  ${theme.mediaQueries.medium} {
    max-height: 65vh;
    max-width: 100vw;
  }

  ${props =>
    props.shouldDisplay &&
    `
    display: block;
  `}
`

export default ({ project, onLoad }) => {
  const {
    slideshow: { document },
  } = project

  if (!document || document.length === 0) {
    return null
  }

  const {
    data: { images },
  } = document[0]

  const [currentIndex, setIndex] = useState(0)

  //
  // Navigation functions
  //
  const navigate = newIndex => setIndex(newIndex)
  const goNext = () => {
    const newIndex = currentIndex + 1 >= images.length ? 0 : currentIndex + 1
    navigate(newIndex)
  }

  //
  // Auto slideshow
  //
  const next = useCallback(goNext, [goNext])
  useEffect(() => {
    const timer = setTimeout(() => next(), 500)

    return () => {
      clearTimeout(timer)
    }
  }, [currentIndex, next])

  return (
    <Container>
      {images &&
        images.map(({ image }, index) => {
          const {
            responsive_medium: { url: src },
          } = image
          return <Image src={src} shouldDisplay={index === currentIndex} />
        })}
    </Container>
  )
}
