import React from "react"
import styled from "styled-components"
import { useSpring, animated, interpolate } from "react-spring"

import { theme } from "src/style"

const Image = styled(animated.img)`
  width: auto;
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  opacity: ${props => props.opacity};
  transform: ${props => props.transform};

  ${theme.mediaQueries.portrait} {
    min-width: auto;
    position: relative;
  }
`
const HomepageAnimatedImage = ({ src, current }) => {
  const scaleValue = current ? 1.15 : 1
  const style = useSpring({
    scale: scaleValue,
    config: {
      mass: 0.9,
      friction: 500,
      tension: 200,
    },
  })

  return (
    <Image
      src={src}
      style={{
        transform: interpolate(
          style.scale,
          imageScale => `scale(${imageScale})`
        ),
      }}
    />
  )
}

export default HomepageAnimatedImage
